/* ContactForm.css */

.contact h1 {
    color: var(--primary);
}

.contact .row {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding-bottom: 1rem;
}

.contact .row .map {
    flex: 1 1 42rem;
    width: 100%;
    padding: 2rem;
    box-shadow: var(--box-shadow);
    border: var(--border);
    border-radius: .5rem;
}

.contact .row form {
    padding: 2rem;
    flex: 1 1 42rem;
    box-shadow: var(--box-shadow);
    border: var(--border);
    text-align: center;
    border-radius: .5rem;
}

.contact .row form h3 {
    font-size: 3rem;
    color: var(--pri);
    padding-bottom: 1rem;
}

.contact .row form .box {
    width: 100%;
    border-radius: .5rem;
    padding: 1rem 1.2rem;
    font-size: 1.6rem;
    text-transform: none;
    border: var(--border);
    margin: .7rem 0;
}

.contact .row form textarea {
    height: 15rem;
    resize: none;
}

.phone-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.my-phone-input .PhoneInputInput {
    width: 100%;
    border-radius: .5rem;
    padding: 1rem 1.2rem;
    font-size: 1.6rem;
    text-transform: none;
    border: var(--border);
    margin: .7rem 0;
}
