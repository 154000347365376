.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: var(--primary);
}

.header .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header .logo {
    width: 90px;
    font-size: 2.5rem;
}

.header .navbar a {
    margin: 0 2rem;
    font-size: 2rem;
    color: var(--black);
    text-decoration: none;
}

.header .navbar a:hover {
    color: var(--yellow);
}

.header .navbar.active {
    background: var(--primary);
}

#language-selector {
    margin-top: 10px;
    display: flex;
}

.flag {
    cursor: pointer;
    margin-right: 10px;
}

.language-option {
    font-size: 1.8em;
    color: white;
}

.language-separator {
    font-size: 1.8em;
    color: white;

}

.header .navbar .flag-container {
    display: none;
}

#menu-btn {
    font-size: 2.5rem;
    color: white;
    display: none;
}


@media (max-width:768px) {
    #menu-btn {
        position: absolute;
        left: 25px;
        top: 50%;
        transform: translateY(-50%);
        height: 24px;
        line-height: 24px;
        transform-origin: center;
    }

    #menu-btn.fa-times {
        transform: translateY(-50%) rotate(180deg);
    }

    .header .flex {
        position: relative;
    }

    .header .flex .logo {
        margin: 0 auto;
    }

    .header .navbar {
        position: absolute;
        top: 99%;
        left: 0;
        right: 0;
        background: var(--primary);
        border-top: var(--border);
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }
    .header .navbar.active .flag-container {
        display: flex;
    }
    .header .navbar.active {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }

    .header .navbar a {
        margin: 2rem;
        display: block;
        font-size: 2rem;
    }

    .header .navbar {
        flex-direction: column;
        align-items: flex-start;
    }

    #menu-btn {
        display: block;
    }

    .header .navbar+#language-selector {
        display: none;
    }

    

    #language-selector.active {
        display: flex;
        flex-direction: column; 
    }

}