@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courgette&family=Noto+Serif+Vithkuqi:wght@500&family=Quicksand:wght@300&family=Satisfy&family=Shadows+Into+Light&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chonburi&display=swap");

:root {
    --primary: #00312B;
    --yellow: #f9d806;
    --black: #ffffff;
    --light-color: #666;
    --box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
    --border: .1rem solid rgba(0, 0, 0, .1);
}

h1,
h2,
h3,
h4,
h5,
h6,
.display-4 {
    color: var(--dark);
    font-weight: 700px;
}

a {
    color: var(--dark);
    text-decoration: none;
}

* {
    font-family: Sora, "sans-serif";
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
    text-decoration: none;
    text-transform: none;
    transition: .2s linear;
}

html {
    font-size: 62.5%;
    overflow-x: hidden;
    scroll-padding-top: 7rem;
    scroll-behavior: smooth;
}

section {
    padding: 2rem;
}

.heading {
    padding-bottom: 2rem;
    text-align: center;
    font-size: 4.5rem;
    color: var(--black);
}

.heading span {
    position: relative;
    z-index: 0;
}

.heading span::before {
    content: '';
    position: absolute;
    bottom: 1rem;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--light-yellow);
    z-index: -1;
    clip-path: polygon(0 90%, 100% 80%, 100% 100%, 0% 100%);
}

.header .logo span {
    color: var(--yellow);
}

.home {
    padding-top: 10rem;
    text-align: center;
    overflow-x: hidden;
}

@keyframes animate__fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media screen and (min-width: 992px) {

    .card {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
    }

    .card-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .product-imgs {
        flex-direction: column;
        justify-content: center;
    }

    .product-content {
        padding-top: 0;
    }


}

@media (max-width:991px) {

    html {
        font-size: 55%;
    }

    .hero h1 {
        font-size: 5em;
    }

    .hero p {
        font-size: 2em;
        margin-bottom: 2rem;
    }

    .hero .btn {
        font-size: 2em;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
    }

}

@media (max-width:768px) {
    .circle {
        margin: 2px;
    }

    .featured .featured-slider .box {
        width: 100%;
        margin: 1rem 0;
    }

    #menu-btn {
        display: block;
    }

    #menu-btn.fa-times {
        transform: rotate(180deg);
    }

    #login-btn .btn {
        display: none;
    }

    #login-btn i {
        display: block;
    }

    .header .flex {
        position: relative;
    }

    .header .navbar {
        position: absolute;
        top: 99%;
        left: 0;
        right: 0;
        background: #fff;
        border-top: var(--border);
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }

    .header .navbar.active {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }

    .header .navbar a {
        margin: 2rem;
        display: block;
        font-size: 2rem;
    }

    .header .navbar {
        display: none;
        flex-direction: column;
        align-items: flex-start;
    }

    .header .navbar.active {
        display: flex;
    }

    #menu-btn {
        display: block;
    }

    .flag-container .flag {
        margin-right: 15px;
    }

    .flag-container.active .flag {
        display: block;
    }

    .flag-container.active {
        display: flex;
    }

    .navigation {
        display: none;
    }
    .flag-container {
        margin-left: 15px;
        margin-bottom: 10px;

    }
    .hero h1 {
        font-size: 5em;
    }

    .hero p {
        font-size: 2em;
        margin-bottom: 2rem;
    }

    .hero .btn {
        font-size: 2em;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
    }

}

@media (max-width:450px) {

    html {
        font-size: 50%;
    }

    .heading {
        font-size: 3rem;
    }

}