.hero {
    background-image: url('../../../../Website_Images/Heros_Banners/hero.jpg');
    background-position: center;
    background-size: cover;
    background-attachment: cover;
    position: relative;
    z-index: 2;
    margin-top: 60px;
}



.hero::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.675);
    z-index: -1;

}

.btn {
    display: inline-block;
    margin-top: 1rem;
    padding: .8rem 3rem;
    background: var(--primary);
    color: var(--black);
    cursor: pointer;
    font-size: 1.7rem;
    border-radius: .5rem;
    font-weight: 500;
    text-align: center;
}

.btn:hover {
    background: var(--primary);
    color: var(--yellow);
}




.hero h1 {
    font-size: 56px;
}

.hero p {
    font-size: 18.5px;
}




@media (max-width:991px) {

   

    .hero h1 {
        font-size: 5em;
    }

    .hero p {
        font-size: 2em;
        margin-bottom: 2rem;
    }

    .hero .btn {
        font-size: 2em;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
    }

}


@media (max-width:768px) {
    .hero h1 {
        font-size: 5em;
    }

    .hero p {
        font-size: 2em;
        margin-bottom: 2rem;
    }

    .hero .btn {
        font-size: 2em;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
    }
}